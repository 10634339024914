<template>
  <div class="funding-card" :class="{ active: funding.investAmount > 0 }">
    <div class="mb-2">
      <template v-if="isWooriCrowd">
        <v-chip
            class="px-2 font-weight-black"
            small
            style="border-radius: 8px !important; font-size: 11px;"
            color="#FFFDC3"
          >
          <!-- <img src="@/assets/images/funding/woori-crowd-6.png" height="15" alt="wc2" style="margin-right: 1px"/> -->
          우리동네펀딩
        </v-chip>
        <span class="mx-1"></span>
      </template>
      <v-chip
        class="px-2"
        small
        style="border-radius: 8px !important; font-size: 11px;"
        color="#0AA3F1"
        text-color="#FAF1F1"
      >
        지난달 매출
        <span class="pl-1">
          {{ truncate(funding.averageMonthlySales) }}만원
        </span>
      </v-chip>
      <span class="mx-1"></span>
      <!-- <template v-if="isFundanowSubType1">
        <v-chip
          class="px-2"
          small
          style="border-radius: 8px !important; font-size: 11px;"
          color="#FF5520"
          text-color="#FAF1F1"
        >
          평균 10일 상환! 초단기 상품
        </v-chip>
      </template> -->
      <template v-if="isEarlyPayment">
        <v-chip
          class="px-2"
          small
          style="border-radius: 8px !important; font-size: 11px;"
          color="#F21B1B"
          text-color="#FAF1F1"
        >
          평균 5일 상환! 초단기 상품
        </v-chip>
      </template>
      <template v-else>
        <v-chip
          class="px-2"
          small
          style="border-radius: 8px !important; font-size: 11px;"
          color="#F9EFFD"
          text-color="#B50AF1"
        >
          펀다AI 예측
          <span class="pl-1">
            {{ truncate(funding.forecastedMonthlySales) }}만원
          </span>
        </v-chip>
      </template>
    </div>

    <div class="mb-2">
      <div class="funding-card__title">
        <!-- <router-link :to="{ path: `/funding/${funding.fundingDataIdx}`}">
          <div class="opener">
            <p class="cursor--pointer text-ellipsis">
              {{ funding.title }}
            </p>
            <span class="icon__glasses ml-1"></span>
            <v-icon
              v-show="funding.alreadyInvested === true"
              class="icon__already"
              small
            >
              mdi-flag-variant
            </v-icon>
          </div>
        </router-link> -->
        <OverlayFnDetailsButton :fundingDataIdx="funding.fundingDataIdx">
          <template #activator="{ on }">
            <div class="opener" v-on="on">
              <p class="cursor--pointer text-ellipsis">
                {{ funding.title }}
              </p>
              <template v-if="isWooriCrowd && funding.disclosure.store.address">
                <span class="ml-1"></span>
                <span style="font-size: 11px;">{{ funding.disclosure.store.address }}</span>
              </template>
              <span class="icon__glasses ml-1"></span>
              <v-icon
                v-show="funding.alreadyInvested === true"
                class="icon__already"
                small
              >
                mdi-flag-variant
              </v-icon>
            </div>
          </template>
        </OverlayFnDetailsButton>

        <div
          v-show="funding.isErr === true"
          class="swal2-icon swal2-warning swal2-icon-show icon--smaill"
          style="display: flex"
        >
          <div class="swal2-icon-content">!</div>
        </div>

        <span
          v-show="funding.isErr === false && funding.investAmount > 0"
          class="icon__check--filled cursor--pointer"
          @click="funding.investAmount = 0"
        ></span>
      </div>
    </div>

    <div class="funding-card__interest mb-1">
      <p class="box-text"> 연 </p>
      <p class="ma-0">
        {{ funding.interestRate | rateFormat }}%

        <span> | </span>

        {{ funding.useDays }}일

        <span> | </span>

        {{ funding.totalAmount | commaFormat }} 원
      </p>
    </div>

    <v-row no-gutters>
      <template v-if="funding.isOpen">
        <v-col cols="2" class="d-flex align-center">
          <LoadingText
            :is-loading="funding.storeBalanceLoading"
            class="ma-0 color--gray-3 font-size--14"
            style="letter-spacing: 0.9px"
          >
            투자금액
          </LoadingText>
        </v-col>
        <v-col cols="10">
          <div class="funding-card__input">
            <div class="input__invest-amount">
              <v-btn
                class="unit-amount"
                @click="dUnitAmount(funding)"
                style="left: 8px"
                icon
                small
              >
                -
              </v-btn>
              <input
                ref="inputInvestAmount"
                v-input-funding="funding.investAmount"
                v-on:blur="investBlur($event.target.value, funding)"
                :placeholder="
                  `${commaFormat(funding.unitInvestAmount)}원 단위로 입력`
                "
                :max="funding.max"
              />
              <v-btn
                class="unit-amount"
                @click="iUnitAmount(funding)"
                style="right: 8px"
                color="#1baaf1"
                icon
                small
              >
                +
              </v-btn>
            </div>
            <span class="font-size--14">원</span>
          </div>
        </v-col>
        <v-col cols="12" v-if="false">
          <v-progress-linear
            :value="investmentProgress()"
            class="investment-progress-bar"
            background-color="#D9D9D9"
            color="#1BAAF1"
            height="12"
          />
        </v-col>
      </template>

      <template v-else>
        <v-col cols="12" class="d-flex align-center justify-center">
          <p class="mt-2 mb-0 color-funda--basic font-size--15">
            {{ funding.investmentStartDate | datetimeKorean }} 오픈
          </p>
        </v-col>
      </template>

      <v-col v-if="funding.isErr" cols="12" class="mt-2">
        <p class="ma-0 color--pink font-size--13">
          {{ funding.errTxt }}
        </p>
      </v-col>

      <v-col cols="12" v-if="xaiGroup !== 't0' && xaiGroup !== 't1'">
        <hr class="width-100 hr-solid my-3">

        <p class="btn__ai-toggle" :class="{open: openXai}" @click="openXai = !openXai">
          펀다 AI 설명 {{ openXai ? '접기' : '펼치기' }}
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 15 10">
              <path d="M8.866 5L3 10.524 4.567 12 12 5 4.567 -2 3 -0.524z" transform="rotate(90 7.5 5)"/>
            </svg>
          </span>
        </p>

        <v-slide-y-transition hide-on-leave>
          <v-row v-show="openXai" no-gutters class="mt-2">
            <template v-if="xaiLoading">
              <v-col cols="12" class="d-flex align-center justify-center" style="height: 90px;">
                <MyLoader />
              </v-col>
            </template>

            <template v-else>
              <v-col :cols="12" class="pr-1">
                <keep-alive>
                  <XAIDetails bubble-color="#F6E0FD">
                    <RadarChart :labels="['고객별매출', '매출건수', '상권지표', '배달매출', '영업일수']" :scores="xaiScores" />
                  </XAIDetails>
                </keep-alive>
              </v-col>
            </template>
          </v-row>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import { InvestmentTypes } from '@/store/types/investment'
import { XaiTypes } from '@/store/types/xai'
import { numberFormat, removeComma, truncate as trc } from '@/util'

export default {
  props: {
    funding: {
      type: Object,
      required: true,
      default: null,
    },
    showXai: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    MyLoader: () => import('@/components/MyLoader.vue'),
    OverlayFnDetailsButton: () => import('@/components/overlay/button/OverlayFnDetailsButton.vue'),
    LoadingText: () => import('@/components/common/text/LoadingText.vue'),
    XAIDetails: () => import('@/components/fundings/XAIDetails.vue'),
    RadarChart: () => import('@/components/fundings/RadarChart.vue'),
  },
  data() {
    return {
      xaiLoading: false,
      openXai: false,
      myXai: null,
      isProto: false,
    }
  },
  computed: {
    fundingData() {
      return this.funding
    },
    // 당겨받기
    isFundanowSubType1() {
      if (this.fundingData?.fundanowType === 'FUNDANOW' && this.fundingData?.useDays <= 30) {
        return true
      }
      return false
    },
    // 초단기
    isEarlyPayment() {
      if (this.fundingData?.fundanowType === 'EARLY_PAYMENT') {
        return true
      }
      return false
    },
    // 우리동네크라우드펀딩
    isWooriCrowd() {
      if (this.fundingData?.disclosure?.disclosureType?.includes('woori_crowd')) {
        return true
      }
      return false
    },
    ...mapGetters('auth', {
      isAuthenticated: AuthTypes.getters.IS_AUTHENTICATED,
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
    ...mapGetters('investment', {
      userP2P: InvestmentTypes.getters.GET_USER_P2P,
    }),
    ...mapGetters('xai', { xaiMsg: XaiTypes.getters.GET_MSG }),
    investAmount() {
      return this.funding?.investAmount ?? null
    },
    xaiGroup() {
      const init = 't0'
      if (!this.isAuthenticated) {
        return init
      }
      const { params } = this.$route
      return params?.group || init
    },
    xaiScores() {
      if (!this.myXai) {
        return []
      }

      const scores = [
        this.myXai.scores.unitPrice_diff_score,
        this.myXai.scores.salesCount_diff_score,
        this.myXai.scores.numSchool_score,
        this.myXai.scores.deliver_diff_score,
        this.myXai.scores.openRate_diff_score,
      ]

      return scores
    },
  },
  watch: {
    investAmount: {
      immediate: true,
      handler(v) {
        if (v > 0) {
          this.getFnStoreBalance()
        }
      }
    },
    openXai: {
      async handler(v) {
        if (v === true) {
          this.fetchXai()
        }
      }
    }
  },
  created() {
    this.updateFundingMax()

    if (this.showXai === true) {
      this.openXai = true
    }
  },
  methods: {
    ...mapActions('xai', { getXaiMsg: XaiTypes.actions.GET_MSG }),
    async fetchXai() {
      if (this.myXai === null) {
        this.xaiLoading = true
        try {
          await this.getXaiMsg({ fundingDataIdx: this.funding.fundingDataIdx, group: this.xaiGroup, userIdx: this.userIdx })
        } finally {
          this.xaiLoading = false
        }
        const msg = this.xaiMsg({ fundingDataIdx: this.funding.fundingDataIdx })
        this.myXai = msg
      }
    },
    commaFormat(value) {
      return numberFormat(value)
    },
    truncate(amount) {
      return numberFormat(trc(amount, 10000))
    },
    updateFundingMax() {
      const f = this.funding

      if (this.isAuthenticated === false) {
        return
      }

      if (this.profile.investmentLimit.totalAmountLimit === 0) {
        return
      }

      const p2p = this.userP2P === -999 ? 0 : this.userP2p
      const fundingBalance = f.investmentBalance ?? 0
      const userBal = this.profile.investmentLimit.totalAmountLimit - p2p
      const userStoreLimit = this.profile.investmentLimit.sameStoreLimit
      const storeLimit = userStoreLimit - fundingBalance

      const limits = [f.max, userBal, storeLimit]
      const result = limits.sort((a, b) => a - b)[0]

      if (result === userBal) {
        f.max = userBal
        f.errTxt = `투자한도 초과! 투자 가능 금액: ${this.commaFormat(
          userBal
        )} 원`
      } else if (result === storeLimit) {
        f.max = storeLimit
        f.errTxt = `이 상점의 투자 가능 금액: ${this.commaFormat(
          storeLimit
        )} 원`
      }
    },
    floorUnitAmount(v, fundingUnit) {
      if (Number.isInteger(v)) {
        let result = parseInt(v / fundingUnit) // 몫
        result = result * fundingUnit
        return result
      }

      return 0
    },
    iUnitAmount(funding) {
      let temp = funding.investAmount ?? 0
      temp += funding.unitInvestAmount
      if (temp < funding.minInvestAmount) {
        temp = funding.minInvestAmount
      }
      funding.investAmount = temp
    },
    dUnitAmount(funding) {
      let temp = funding.investAmount ?? 0
      if (temp < 1) {
        return
      }

      temp -= funding.unitInvestAmount
      if (temp < funding.minInvestAmount) {
        temp = 0
      }
      funding.investAmount = temp
    },
    investBlur(value, funding) {
      const v = parseInt(removeComma(value), 10)
      if (isNaN(v)) {
        return
      }

      const minInv = this.commaFormat(funding.minInvestAmount)
      let temp = this.floorUnitAmount(v, funding.unitInvestAmount)
      if (temp < funding.minInvestAmount) {
        this.$swal.basic.alert(
          `해당 상품은 ${minInv}원부터 투자하실 수 있습니다`
        )
        temp = funding.minInvestAmount
      }
      funding.investAmount = temp
    },
    investmentProgress() {
      const max = 100
      const start = 25
      return start + (max - start) * this.funding.amtRate
    },
    async getFnStoreBalance() {
      const f = this.funding

      if (
        this.isAuthenticated === false ||
        f.storeBalanceLoading === true ||
        Number.isInteger(f.investmentBalance)
      ) {
        return
      }

      f.storeBalanceLoading = true

      const q = `data: getInvestmentBalanceByFundingDataIdx(
          userIdx: ${this.userIdx}
          fundingDataIdx: ${f.fundingDataIdx}
        )`

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        if (data == null) {
          data = 0
        }
        f.investmentBalance = data
      } catch (e) {
        f.investmentBalance = 0
      } finally {
        f.storeBalanceLoading = false
        this.updateFundingMax(f)
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.funding-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);
  font-weight: bold;

  margin-bottom: 15px;
  padding: 10px 16px;

  &.active {
    // background-color: rgb(27, 170, 241, 0.25);
    // background-color: rgb(83, 160, 201, 0.1);
    background-color: rgb(244, 250, 255, 0.8);
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      max-width: 90%;
    }

    .opener {
      display: flex;
      align-items: center;
    }

    p {
      margin: 0;
      color: #333333;
      font-size: 16px;
    }
  }

  &__interest {
    display: flex;
    align-items: center;

    color: #333333;
    font-size: 14px;
  }

  &__input {
    display: flex;
    align-items: center;
    color: #333333;
    justify-content: flex-end;

    .input__invest-amount {
      position: relative;
      width: 90%;
      display: inline-flex;
      align-items: center;

      input {
        width: 100%;

        background-color: #F7F7F7;
        border-radius: 8px;
        text-align: center;

        padding: 12px;

        &::placeholder {
          font-size: 13px;
        }
      }

      .unit-amount {
        position: absolute;
        width: 32px;

        background-color: #FFFFFF;
        border-radius: 8px;
        cursor: pointer;
        font-size: 22px;
      }
    }
  }

  &__line {
    padding-top: 12px;
    border-bottom: 1px solid #cccccc;
  }

  .box-text {
    width: 23px;
    height: 23px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #F5F5F5;
    border-radius: 10px;
    color: #696969;
    font-size: 12px;

    margin-right: 4px;
    margin-bottom: 0;

    &.already {
      background-color: #268bef;
    }
  }

  .btn__ai-toggle {
    color: #B50AF1;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: 0;

    &.open {
      color: #484848;

      span {
        svg {
          transform: rotate(180deg);
          fill: #727D8C;
        }
      }
    }

    span {
      vertical-align: middle;
      svg {
        fill: #B50AF1;
      }
    }
  }
}
</style>
